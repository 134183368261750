import { ENGLISH } from '@redux/constants'
import { checkArgsEmpty, sendRequest } from 'api/apiHelper'
import { getApiUrl, getOldApiUrl } from 'services/utils/configUtils'
import EwayRouter from 'shared/EwayRouter'
import { TURN_TO_USER_TOKEN_ROUTE } from 'shared/EwayRouter/EwayRouterConstants'
const OLD_API_URL = getOldApiUrl()
const API_URL = getApiUrl()
/**
 * Name: FetchCardListData
 * Desc: get shopping list for list tile
 * @param {*} language
 */
const buildFetchCardListData = async (args) => {
  const [language = ENGLISH] = args

  const url = `${OLD_API_URL}/api/shoppinglists/GetAllShoppingLists`
  const options = {
    headers: {
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = await sendRequest(url, options)
  return response
}
/**
 * Name: AddProductToList
 * Desc: Add Product In Shopping List
 * @param {*} language
 */
const buildAddProductToList = async (args) => {
  const [language = ENGLISH, shoppingListId, params] = args
  const { reqParams } = params
  const url = `${API_URL}/shoppingLists/${shoppingListId}/products`
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = await sendRequest(url, options)
  return response
}

/**
 * Name: buildFetchTurnToUserToken
 * Desc: create user token for turnto rating & review
 * @param {*} args
 */
const buildFetchTurnToUserToken = async (args) => {
  const [language, reqParams] = args
  const url = EwayRouter.getUrl(TURN_TO_USER_TOKEN_ROUTE)
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = await sendRequest(url, options)
  return response
}

/**
 * Export methods
 */
export const getCardListData = checkArgsEmpty(buildFetchCardListData)

export const addProductToList = checkArgsEmpty(buildAddProductToList)
export const fetchTurnToUserToken = checkArgsEmpty(buildFetchTurnToUserToken)
