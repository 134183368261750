import { checkArgsEmpty, sendRequest } from 'api/apiHelper'
import sortBy from 'lodash/sortBy'
import take from 'lodash/take'
import { getHostUrl, getOldApiUrl } from 'services/utils/configUtils'
import { addProductToList, getCardListData } from '~/api/shoppingListOperations'
import pdpMessages from '~/components/containers/ProductDetail/ProductDetailPrimarySection/ProductDetailContainer/ProductDetailSection/messages'
import messages from '~/components/containers/ShoppingCart/ShoppingCartUtilityPanel/messages'
import {
  MESSAGE_STATUS,
  MESSAGE_TYPE,
} from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationConstants'
import {
  bySuccessOrErrorMessages,
  formatWithPrimaryMessageAndType,
} from '~/components/shared/Alerts/AlertNotificationUtils/AlertNotificationFilteringUtils'
import EwayRouter from '~/components/shared/EwayRouter'
import {
  SHOPPING_LIST_ROUTE,
  SHOPPING_LIST_SAVE_ROUTE,
} from '~/components/shared/EwayRouter/EwayRouterConstants'
import { ENGLISH } from '~/redux/constants'
import { isEnglish } from './utils/languageUtils'
const HOST_URL = getHostUrl()
const OLD_API_URL = getOldApiUrl()
/**
 * Name: fetchCardListData
 * Desc: Fetch data for List tile on home page
 * @param {*} language
 */
export async function fetchCardListData(language) {
  return await getCardListData(language)
}

/**
 * Name: cardListData
 * Desc: formats HomePage Membership Dashboard Shopping List Data
 * @param {*} language
 */
export async function cardListData(language = ENGLISH) {
  const { ShoppingListInfosModel } = await fetchCardListData(language)
  const items = filterCardListData(ShoppingListInfosModel, language)
  const showViewAll = items.some((list) => !!list.id) // show "View All" button if theres an id in one of them
  const data = {
    viewAllUrl: EwayRouter.getUrl(SHOPPING_LIST_ROUTE),
    showViewAll,
    items,
  }
  return data
}

/**
 * Name: filterCardListData
 * Desc: Prepare data for UI
 * @param {*} cardListData
 * @param {*} language
 */
export function filterCardListData(cardListData = [], language = ENGLISH) {
  const {
    itemLabel: singular,
    moreItemsLabel: plural,
    createListLabel,
  } = messages[language]
  const sortedData = sortBy(cardListData, ['Name']) // should be done by API via ?sortBy=Name
  const formattedData = sortedData.map((item) => {
    const {
      NbProducts: count,
      FirstItemImage: src = '',
      ShoppingListId: shoppingListId = '',
    } = item
    const en = isEnglish(language)
    const label = (en && count === 1) || (!en && count < 2) ? singular : plural
    const countLabel = count ? label : ''
    const finalCount = count === -1 ? '0' : count
    const listUrl = `${HOST_URL}/${language}/ShoppingList/ShoppingList/GetShoppingListDetails/${shoppingListId}/1`
    return {
      id: shoppingListId,
      count: finalCount,
      countLabel,
      title: item.Name,
      src,
      href: listUrl,
    }
  })

  // Only take the first 2 items to show them in UI
  const MAX_ITEMS_TO_TAKE = 2
  const firstItems = take(formattedData, MAX_ITEMS_TO_TAKE)

  // Since we fill up the UI only with 3 elements, we always leave a space to "Create a list"
  const TOTAL_TO_FILL = 3
  const lengthToFill = TOTAL_TO_FILL - firstItems.length
  const restToFill = Array.from({ length: lengthToFill }).map(() => ({
    href: EwayRouter.getUrl(SHOPPING_LIST_SAVE_ROUTE),
    title: createListLabel,
  }))
  return [...firstItems, ...restToFill]
}

/**
 * Name: addToShoppingListservice
 * Desc: Add Product To List
 * @param {*} language
 */
export async function addToShoppingList(language, shoppingListId, reqParams) {
  const addProductToListData = await addProductToList(
    language,
    shoppingListId,
    reqParams
  )
  return addProductToListData
}

export const addProductInShoppingList = async (
  shoppingListId,
  language,
  productDetailData,
  setAddToCartMessages
) => {
  const { ProductCode, Multiple } = productDetailData

  if (shoppingListId && productDetailData && Multiple) {
    const reqParams = [
      {
        ProductId: ProductCode,
        Quantity: Multiple,
        LineNumber: 1,
      },
    ]
    const addToShoppingListData = await addToShoppingList(
      language,
      shoppingListId,
      { reqParams }
    )

    const addToCartWarningMsg = [
      {
        PrimaryMessage: pdpMessages[language].itemHasBeenSuccessfullyLabel,
        ButtonText: null,
        ButtonLink: null,
        SecondaryMessages: [],
        HasSecondaryMessages: false,
        ShowExpandedMessage: false,
        MessageType: MESSAGE_TYPE.OTHER,
        MessageStatus: MESSAGE_STATUS.SUCCESS,
        MessagePosition: 0,
        StartDate: '',
      },
    ]
    if (addToShoppingListData && addToShoppingListData.shoppingListId) {
      addToCartWarningMsg[0].PrimaryMessage =
        pdpMessages[language].itemHasBeenSuccessfullyLabel
      addToCartWarningMsg[0].MessageStatus = MESSAGE_STATUS.SUCCESS
    } else {
      addToCartWarningMsg[0].PrimaryMessage =
        pdpMessages[language].thisItemNotAvailableLabel
      addToCartWarningMsg[0].MessageStatus = MESSAGE_STATUS.ERROR
    }
    const messages = addToCartWarningMsg
      ?.filter(bySuccessOrErrorMessages)
      .map(formatWithPrimaryMessageAndType)
      .filter(Boolean)
    setAddToCartMessages(messages)
  }
}

/**
 * Name: addOrderToShoppingList
 * Desc: update shopping list based on an
 * @param {*} language - the language
 * @param {*} shoppingListId - the product id
 * @param {*} orderId - the order in question
 */
const buildUpdateShoppingList = async (args) => {
  const [language, shoppingListId, orderId] = args
  const url = `${OLD_API_URL}/api/shoppinglists/addToShoppingList`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      ShoppingListId: shoppingListId,
      OrderId: orderId,
    }),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  try {
    const result = await sendRequest(url, options)
    return { isSuccess: result.status === 200, result }
  } catch (e) {
    return { isSuccess: false, status: 400, message: e, result: {} }
  }
}
export const addOrderToShoppingList = checkArgsEmpty(buildUpdateShoppingList)

export function formatAddOrderToShoppingListResponse(data) {
  const { isSuccess } = data
  const { Messages } = data.result.MessageInfo
  return isSuccess
    ? {
        isSuccess,
        messages: Messages.map((e) => {
          const messageType = getMessageType(e.Id)
          return { message: e.PrimaryMessage, id: e.Id, type: messageType }
        }),
      }
    : { isSuccess, messages: [] }
}

// Status that come from the API.
const ADDED = 'added'
const ALREADY_EXISTS = 'alreadyExist'
const NOT_ADDED = 'notAdded'

function getMessageType(id) {
  switch (id) {
    case ADDED:
      return 'success'
    case ALREADY_EXISTS:
      return 'warn'
    case NOT_ADDED:
      return 'error'
    default:
      return 'success'
  }
}
